import { useState } from "react"
import styled from "styled-components"

import { Tooltip } from "@material-ui/core"

import {
  SmokeFeedbackDialog,
  TSmokeEventFeedbackData,
} from "src/components/EventLog/SmokeFeedbackDialog"
import { UpgradeBlockerDialog } from "src/components/FeatureBlockers/UpgradeBlockerDialog"
import {
  CardStatusRow,
  MonitoringCard,
  StatusLeft,
} from "src/components/Homes/HomeDetails/Overview/MonitoringCard"
import { SmokingDetectionPopover } from "src/components/Homes/HomeDetails/SmokingDetection/SmokingDetectionPopover"
import { usePostCigaretteReportFindingsClicked } from "src/data/analytics/queries/smokingDetectionAnalyticQueries"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { useFetchEvents } from "src/data/events/queries/eventQueries"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { Feature } from "src/data/featureAvailability/types/featureAvailabilityTypes"
import { ISmokingDetection, THome } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useTranslate } from "src/i18n/useTranslate"
import { useSearchParams } from "src/router/useSearchParams"
import { MButton } from "src/ui/Button/MButton"
import CalibratingIcon from "src/ui/icons/calibrating.svg"
import SmokingDetectionIcon from "src/ui/icons/cigarette-detection.svg"
import AlertingIcon from "src/ui/icons/important-outlined.svg"
import { spacing } from "src/ui/spacing"
import { formatDateAsTime } from "src/utils/l10n"

type THomeData = Pick<THome, "home_id" | "violations" | "timezone" | "address">

export function SmokingDetectionCard({
  smokingDetection,
  toggleAllowed,
  devices,
  homeData,
}: {
  smokingDetection: ISmokingDetection
  toggleAllowed: boolean
  devices: TDevice[]
  homeData: THomeData
}) {
  const { t, langKeys } = useTranslate()
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [showUpgradeDialog, setShowUpgradeDialog] = useState(false)

  const isEnabled = smokingDetection.state === "enabled"
  const isAnalyzing =
    isEnabled &&
    !!smokingDetection.calibration_done_at &&
    new Date() < new Date(smokingDetection.calibration_done_at)
  const statusContents = isEnabled ? t(langKeys.on) : t(langKeys.off)

  const featureAvailabilityCigaretteSmoke = useFeatureAvailability({
    feature: Feature.CIGARETTE_SMOKE,
  })
  const CigaretteFeatureAvailableInPlan =
    featureAvailabilityCigaretteSmoke.available &&
    featureAvailabilityCigaretteSmoke.ready

  function handleClickToggle(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    CigaretteFeatureAvailableInPlan ? setOpen(true) : setShowUpgradeDialog(true)

    setAnchorEl(event.currentTarget)
  }

  const tooltipText = toggleAllowed ? "" : t(langKeys.not_enough_permissions)

  function handleClose() {
    setOpen(false)
    setAnchorEl(null)
  }
  return (
    <div>
      <MonitoringCard
        titleSlots={[
          <StatusLeft
            key="smoke-status-left"
            icon={<SmokingDetectionIcon width={32} height={32} />}
            title={t(langKeys.smoking_detection_title)}
            statusContents={statusContents}
            isOn={isEnabled}
            showUpgradeButton={
              !featureAvailabilityCigaretteSmoke.available ||
              !featureAvailabilityCigaretteSmoke.ready
            }
          />,
          <Tooltip key="smoke-tooltip" title={tooltipText} placement={"top"}>
            <div>
              <MButton
                variant="subtle"
                size="small"
                key="right"
                onClick={handleClickToggle}
                disabled={!toggleAllowed}
                loading={!featureAvailabilityCigaretteSmoke.ready}
              >
                {t(langKeys.manage)}
              </MButton>
            </div>
          </Tooltip>,
        ]}
        cardFooter={{
          showTopBorder: isAnalyzing || smokingDetection.status === "alerting",
          component: (
            <CigaretteDetectionFooter
              isAnalyzing={isAnalyzing}
              status={smokingDetection.status}
              devices={devices}
              homeData={homeData}
            />
          ),
        }}
      ></MonitoringCard>

      {open && (
        <SmokingDetectionPopover
          homeId={homeData.home_id}
          anchorEl={anchorEl}
          violations={homeData.violations}
          state={smokingDetection.state}
          onClose={handleClose}
        />
      )}

      <UpgradeBlockerDialog
        context="smoking_detection"
        open={showUpgradeDialog}
        onClose={() => setShowUpgradeDialog(false)}
      />
    </div>
  )
}

const reportDialogOpenKey = "reportDialogOpen"

function CigaretteDetectionFooter({
  isAnalyzing,
  status,
  devices,
  homeData,
}: {
  isAnalyzing: boolean
  status: ISmokingDetection["status"]
  devices: TDevice[]
  homeData: THomeData
}) {
  const { t, langKeys } = useTranslate()
  const { orgId } = useOrganization()

  const postCigaretteReportFindingsClicked =
    usePostCigaretteReportFindingsClicked()

  const { searchParams: filter, setSearchParams: setFilter } = useSearchParams({
    keys: [{ key: reportDialogOpenKey, type: "boolean" }],
  })

  // TODO MON-790: Use new home log endpoint instead to get the corresponding event
  const fetchLatestSmokingEvent = useFetchEvents({
    limit: 1,
    organizationId: orgId,
    eventIdFilter: ["smoking_detection_smoking_detected"],
    homeIds: [homeData.home_id],
    options: {
      select(data) {
        return data.events?.[0]
      },
    },
  })
  const eventFeedbackData: TSmokeEventFeedbackData | undefined =
    fetchLatestSmokingEvent.data
      ? {
          area: "",
          address: "",
          date: fetchLatestSmokingEvent.data.created_at,
          timezone: homeData.timezone,
          eventId: fetchLatestSmokingEvent.data.event_id,
          deviceId: fetchLatestSmokingEvent.data.device_id,
          homeId: homeData.home_id,
        }
      : undefined

  const clockType = useGetUser().clock_type

  if (isAnalyzing) {
    return (
      <CardStatusRow
        icon={<CalibratingIcon width={24} />}
        subtitle={t(langKeys.smoking_detection_calibrating_title)}
        body={t(langKeys.smoking_detection_calibrating_body)}
      />
    )
  }

  function handleReportFindingsClick() {
    postCigaretteReportFindingsClicked.mutate({ context: "home" }) // tracking
    setFilter(reportDialogOpenKey, true) // open dialog
  }

  if (status === "alerting") {
    const alertingDevice = devices.find(
      (device) => device.smoking_detection?.status === "alerting"
    )

    const alertTime = formatDateAsTime({
      date: alertingDevice?.smoking_detection?.updated_at || "",
      clockType,
      excludeSeconds: true,
    })
    const deviceName = alertingDevice?.description

    return (
      <BodyBox>
        <CardStatusRow
          icon={<AlertingIcon width={24} />}
          subtitle={t(langKeys.smoking_detection_cigarette_smoke_detected)}
          body={
            <StatusColumn>
              <StatusColumnRow>
                <div>{alertTime}</div>
                <div>&#x2022;</div>
                <div>{deviceName}</div>
              </StatusColumnRow>

              <StatusColumnRow>
                {t(langKeys.smoking_detection_cigarette_smoke_info)}
              </StatusColumnRow>
            </StatusColumn>
          }
          color="emergency"
        />

        <ActionColumn>
          <MButton onClick={handleReportFindingsClick}>
            {t(langKeys.report_findings)}
          </MButton>
        </ActionColumn>

        {eventFeedbackData && (
          <SmokeFeedbackDialog
            open={!!filter.reportDialogOpen}
            onClose={() => {
              setFilter("reportDialogOpen", false)
            }}
            feedbackData={eventFeedbackData}
            context="home"
            // TODO MON-790: Use new home event feedback endpoint instead
            homeEventFeedback={false}
          />
        )}
      </BodyBox>
    )
  }

  return null
}

const BodyBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing.L} ${spacing.XS2};

  & > div:first-child {
    flex: 1 1 auto;
  }
`

const StatusColumn = styled.div`
  display: grid;
  gap: ${spacing.XS2};
`

const StatusColumnRow = styled.div<{ $hidden?: boolean }>`
  display: ${({ $hidden }) => ($hidden ? "none" : "flex")};
  gap: ${spacing.XS2};
`

const ActionColumn = styled.div`
  align-self: center;
`
